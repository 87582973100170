import "./CustomLoader.scss";
import React from "react";
import WellnessLogo from "../../assets/images/logos/sq.png";


const CustomLoader = () => {
  
  return (
    <div className="custom-loader-component flex-grow-1 h-100 w-100 d-flex justify-content-center align-items-center">
      <img src={WellnessLogo} alt={"wellness studio"} />
    </div>
  );
};

export default CustomLoader;
