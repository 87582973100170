import "./ClassCard.scss";
import React from "react";
import ReformerIcon from "../../assets/icons/pilates-icon.svg";
import BarreIcon from "../../assets/icons/barre-icon.svg";
import SculptIcon from "../../assets/icons/sculpt-icon.svg";
import useDates from "../../hooks/useDates";
import { Iclass } from "../../interfaces/Classes";
import { Link } from "react-router-dom";

type ClassCardProps = {
  clase: Iclass;
};

const image = {
  REFORMER: <img src={ReformerIcon} alt={"pilates reformer"} />,
  BARRE: <img src={BarreIcon} alt={"barre reformer"} />,
  SCULPT: <img src={SculptIcon} alt={"sculpt reformer"} />,
};

const ClassCard = ({ clase }: ClassCardProps) => {
  const { getHourInHermosillo } = useDates();
  return (
    <div className="card class-card-component">
      <div className="image-container">{image[clase.lesson_name]}</div>
      <div className="card-body d-flex flex-column gap-2">
        <div className="row">
          <h5 className="fs-14 fw-bold">{clase.lesson_name}</h5>
          <h5 className="fs-14 fw-medium ms-auto">
            {getHourInHermosillo(clase.schedule_startDate)} -{" "}
            {getHourInHermosillo(clase.schedule_endDate)}
          </h5>
        </div>
        <h5 className="fs-12 fw-bolder text-end">{clase.location_name}</h5>

        <Link
          to={"/clase/" + clase.schedule_id}
          className="btn btn-primary fs-12"
        >
          Ver
        </Link>
      </div>
    </div>
  );
};

export default ClassCard;
