import { useFormikContext } from "formik";
import { PlanI } from "../../../../interfaces/Plans";
import "./EditPlansAmounts.scss";
import React, { ChangeEvent, useEffect } from "react";

interface FormValues {
  selectedPlans: PlanI[];
}

const EditPlansAmounts = () => {
  const { setFieldValue, values } = useFormikContext<FormValues>();

  const handlePlanAmountChange = (
    e: ChangeEvent<HTMLInputElement>,
    plan: PlanI
  ) => {
    const tempValues = [...values.selectedPlans];
    const planIndex = tempValues.findIndex((e) => e.id === plan.id);

    if (planIndex !== -1) {
      tempValues[planIndex].lessons.pricing[plan.pricingIndex].cost =
        e.target.value;

      setFieldValue("selectedPlans", tempValues);
    }
  };

  return (
    <div className="edit-plans-amounts-component-container shadow rounded p-4">
      <div className="summary-table">
        <div className="top">
          <h3>Resumen de compra</h3>
        </div>
        <div className="summary">
          <h3>Detalles de precio</h3>
          {values.selectedPlans.map((plan, i) => {
            return (
              <div
                className="sub-total d-flex justify-content-between mt-4"
                key={
                  "plan-subtotal-" + plan.lessons.pricing[plan.pricingIndex].id
                }
              >
                <div>
                  <p>{plan.name}</p>
                  <p>
                    {plan.lessons.pricing[plan.pricingIndex].reservations === 30
                      ? "ilimitado"
                      : plan.lessons.pricing[plan.pricingIndex]
                          .reservations}{" "}
                    {plan.lessons.pricing[plan.pricingIndex].reservations === 30
                      ? ""
                      : plan.lessons.pricing[plan.pricingIndex].reservations ===
                        1
                      ? "reserva"
                      : "reservas"}
                  </p>
                </div>
                <div className="d-flex align-items-center gap-2">
                  <h3 className="fs-14 fw-bold m-0">$</h3>
                  <input
                    key={plan.lessons.pricing[plan.pricingIndex].id}
                    className="form-control text-end"
                    type="number"
                    name={"totalAmount-plan-" + i}
                    id={"totalAmount-plan-" + i}
                    value={parseInt(
                      plan.lessons.pricing[plan.pricingIndex].cost
                    )}
                    onChange={(e) => handlePlanAmountChange(e, plan)}
                  />
                  <h3 className="fs-14 fw-bold m-0">MXN</h3>
                </div>
              </div>
            );
          })}
        </div>
        <div className="total d-flex justify-content-between">
          <h3>Total:</h3>
          <h3>
            $
            {values.selectedPlans
              .reduce((sum, plan) => {
                return (
                  sum + parseInt(plan.lessons.pricing[plan.pricingIndex].cost)
                );
              }, 0)
              .toFixed(2)}{" "}
            MXN
          </h3>
        </div>
      </div>
    </div>
  );
};

export default EditPlansAmounts;
