import { handleLogin } from "../services/Auth";


export const useAuth = () => {
  const login = async (email: string, password: string) => {
    return handleLogin(email, password)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        throw error;
      });
  };

  const signOut = () => {
    return localStorage.removeItem("userToken");
  };

  return { login, signOut };
};
