import { useFormik } from "formik";
import React, { useState } from "react";
import { object, string } from "yup";
import { fetchReports } from "../../../../services/Teachers";
import { ReportClassI } from "../../../../interfaces/Classes";
import { useReportsContext } from "../../../../context/ReportsContext";

const filterSchema = object({
  startDate: string().required("Este campo es obligatorio"),
  endDate: string().required("Este campo es obligatorio"),
});

const FilterNav = () => {
  const { setClases, setSummaryClasses } = useReportsContext();

  const formik = useFormik({
    initialValues: {
      startDate: "",
      endDate: "",
    },
    validationSchema: filterSchema,
    onSubmit: (values) => {
      fetchReports(values.startDate, values.endDate)
        .then((res) => {
          setClases(res.classes);
          setSummaryClasses({
            valid: res.validClasses,
            invalid: res.invalidClasses,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },
  });

  return (
    <div className="reportes-filter-nav-component p-4 shadow rounded">
      <h2 className="mb-4">Reportes</h2>
      <form
        className="row align-items-start gap-4"
        onSubmit={formik.handleSubmit}
      >
        <div className="input-group d-flex flex-column align-items-start">
          <label htmlFor="startDate"></label>
          <input
            type="date"
            className="form-control w-100"
            name="startDate"
            id="startDate"
            value={formik.values.startDate}
            onChange={formik.handleChange}
          />
          {formik.touched.startDate && formik.errors.startDate && (
            <div className="invalid-form-field fs-12 text-danger fw-bold">
              {formik.errors.startDate}
            </div>
          )}
        </div>
        <h2>a</h2>
        <div className="input-group flex-column align-items-start">
          <label htmlFor="endDate"></label>
          <input
            type="date"
            className="form-control w-100"
            name="endDate"
            id="endDate"
            value={formik.values.endDate}
            onChange={formik.handleChange}
          />
          {formik.touched.endDate && formik.errors.endDate && (
            <div className="invalid-form-field fs-12 text-danger fw-bold">
              {formik.errors.endDate}
            </div>
          )}
        </div>
        <button className="btn btn-primary px-4" type="submit">
          Filtrar
        </button>
      </form>
    </div>
  );
};

export default FilterNav;
