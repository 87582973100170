import React, { useEffect, useState } from "react";
import useDates from "../../../../hooks/useDates";
import { usePaymentsContext } from "../../../../context/PaymentsContext";

const paymentMethods = {
  credit: "Tarjeta de crédito",
  debit: "Tarjeta de débito",
  transfer: "Transferencia",
  deposit: "Depósito",
  cash: "Efectivo",
  other: "Otro",
};
const PaymentsTable = () => {
  const { primaryFormatDate } = useDates();
  const { getPaymentsByTeacher, payments } = usePaymentsContext();

  useEffect(() => {
    getPaymentsByTeacher();
  }, []);

  return (
    <div className="table-responsive">
      <table className="table table-hover">
        <thead>
          <tr>
            <th scope="col">Alumno</th>
            <th scope="col">Membresía</th>
            <th scope="col">Fecha</th>
            <th scope="col">Tipo</th>
            <th scope="col">Concepto</th>
            {/* <th scope="col">Detalles</th> */}
          </tr>
        </thead>
        <tbody>
          {Array.isArray(payments) &&
            payments.map((payment) => {
              return (
                <tr key={payment.payment_id}>
                  <td>
                    {payment.user_name} {payment.user_lastname}
                  </td>
                  <td>{payment.lesson_name}</td>
                  <td>{primaryFormatDate(payment.payment_datetime)}</td>
                  <td>{paymentMethods[payment.payment_paymentMethod]}</td>
                  <td>{payment.payment_noReference}</td>
                  {/* <td>
                  <Image src={EyeIcon} alt={"eye"} role="button" />
                </td> */}
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default PaymentsTable;
