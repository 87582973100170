import React, { useEffect } from "react";
import "./styles/CustomBootstrap.scss";
import "./styles/index.scss";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import {
  Outlet,
  RouterProvider,
  createBrowserRouter,
  useNavigate,
} from "react-router-dom";
import UserContextProvider, { useUserContext } from "./context/UserContext";
import MainLayout from "./layouts/MainLayout";
import ErrorRoute from "./components/ErrorRoute/ErrorRoute";
import { Toaster } from "react-hot-toast";
import Login from "./pages/Login/Login";
import Home from "./pages/Home/Home";
import ClassContextProvider from "./context/ClassContext";
import Clase from "./pages/Clase/Clase";
import Pagos from "./pages/Pagos/Pagos";
import Reportes from "./pages/Reportes/Reportes";
import AddPlan from "./pages/AddPlan/AddPlan";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

function AuthWrapper() {
  const { user } = useUserContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [user, navigate]);

  if (!user) return <></>;

  return <Outlet />;
}

function PublicRoute() {
  const { user } = useUserContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [user, navigate]);

  if (user) return <></>;
  return <Outlet />;
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        path: "",
        element: <AuthWrapper />,
        children: [
          {
            path: "",
            element: <Home />,
          },
          {
            path: "pagos",
            element: <Pagos />,
          },
          {
            path: "reportes",
            element: <Reportes />,
          },
          {
            path: "agregar-plan",
            element: <AddPlan />,
          },
          {
            path: "clase",
            element: (
              <ClassContextProvider>
                <Outlet />
              </ClassContextProvider>
            ),
            children: [{ path: ":classId", element: <Clase /> }],
          },
        ],
      },
      {
        path: "login",
        element: <PublicRoute />,
        children: [
          {
            path: "",
            element: <Login />,
          },
        ],
      },
      {
        path: "*",
        element: <ErrorRoute />,
      },
    ],
  },
]);
root.render(
  <React.StrictMode>
    <UserContextProvider>
      <Toaster />
      <RouterProvider router={router} />
    </UserContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.error))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
