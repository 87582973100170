import React from "react";
import { useReportsContext } from "../../../../context/ReportsContext";

const SummaryReports = () => {
  const { summaryClasses } = useReportsContext();
  return (
    <div className="d-flex flex-wrap flex-md-row gap-4 gap-md-8">
      <h2 className="fs-18 fw-bold">
        Total de clases: {summaryClasses.valid + summaryClasses.invalid}
      </h2>
      <h2 className="fs-18 fw-bold">Clases validas: {summaryClasses.valid}</h2>
      <h2 className="fs-18 fw-bold">
        Clases invalidas: {summaryClasses.invalid}
      </h2>
    </div>
  );
};

export default SummaryReports;
