import React, { createContext, useContext, useEffect, useState } from "react";
import { useUserContext } from "./UserContext";
import { IFullClass } from "../interfaces/Classes";
import { UserT } from "../interfaces/User";
import { useNavigate, useParams } from "react-router-dom";
import { notifyError, notifySuccess } from "../services/ToastNotifications";
import {
  fetchAttendance,
  fetchClassesByScheduleId,
  fetchStudentsFromClass,
  toggleClassStatusByTeacher,
} from "../services/ClassesService";

type ClassContextT = {
  clase: IFullClass | null;
  loading: boolean;
  students: UserT[];
  filter: ClassStudentsFilterT;
  handleClassFilter: (newFilter: ClassStudentsFilterT) => void;
  addUserModal: boolean;
  setAddUserModal: React.Dispatch<React.SetStateAction<boolean>>;
  takingAttendance: boolean;
  setTakingAttendance: React.Dispatch<React.SetStateAction<boolean>>;
  attendance: Array<string>;
  handleAttendance: (userId: string) => void;
  toggleClassStatus: (scheduleId: string) => void;
  showChangeTeacherModal: boolean;
  setShowChangeTeacherModal: React.Dispatch<React.SetStateAction<boolean>>;
  getStudents: (scheduleId: string) => void;
};

type ClassStudentsFilterT = "all" | "new" | "attended" | "notAttended";

const ClassContext = createContext<ClassContextT>({
  clase: null,
  loading: true,
  students: [],
  filter: "all",
  handleClassFilter: () => {},
  addUserModal: false,
  setAddUserModal: () => {},
  takingAttendance: false,
  setTakingAttendance: () => {},
  attendance: [],
  handleAttendance: () => {},
  toggleClassStatus: () => {},
  showChangeTeacherModal: false,
  setShowChangeTeacherModal: () => {},
  getStudents: () => {},
});

export const useClassContext = () => {
  return useContext(ClassContext);
};

const ClassContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [clase, setClase] = useState<IFullClass | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [students, setStudents] = useState<UserT[]>([]);
  const [filter, setFilter] = useState<ClassStudentsFilterT>("all");
  const { classId } = useParams();
  const navigate = useNavigate();
  const [addUserModal, setAddUserModal] = useState<boolean>(false);
  const [takingAttendance, setTakingAttendance] = useState<boolean>(false);
  const [attendance, setAttendance] = useState<Array<string>>([]);
  const [showChangeTeacherModal, setShowChangeTeacherModal] =
    useState<boolean>(false);
  const { user } = useUserContext();

  const handleClassFilter = (newFilter: ClassStudentsFilterT) => {
    setFilter(newFilter);
  };

  const getClassInfo = async (wait: boolean = false) => {
    if (classId) {
      fetchClassesByScheduleId(classId).then((res) => {
        if (res && res.employeeId === user?.id) {
          if (wait) {
            notifySuccess(
              res.status === "OPEN" ? "Clase abierta" : "Clase cerrada"
            );
          }
          setClase(res);
          getStudents(res.id);
          setLoading(false);
        } else {
          navigate("/");
        }
      });
    }
  };

  const getStudents = async (scheduleId: string) => {
    await fetchStudentsFromClass(scheduleId).then((res) => {
      setStudents(res);
    });

    await fetchAttendance(scheduleId).then((res) => {
      setAttendance(res);
    });
  };

  const handleAttendance = (userId: string) => {
    const temp = [...attendance];
    const userIndex = temp.findIndex((e) => e === userId);

    if (userIndex !== -1) {
      temp.splice(userIndex, 1);
    } else {
      temp.push(userId);
    }
    setAttendance(temp);
  };

  const toggleClassStatus = async (scheduleId: string) => {
    await toggleClassStatusByTeacher(scheduleId, user!.id)
      .then((res) => {
        getClassInfo(true);
      })
      .catch((error) => {
        notifyError(error.response.data.message);
        console.error(error);
      });
  };

  useEffect(() => {
    if (classId) {
      getClassInfo();
    }
  }, []);

  return (
    <ClassContext.Provider
      value={{
        clase,
        loading,
        students,
        filter,
        handleClassFilter,
        addUserModal,
        setAddUserModal,
        takingAttendance,
        setTakingAttendance,
        attendance,
        handleAttendance,
        toggleClassStatus,
        showChangeTeacherModal,
        setShowChangeTeacherModal,
        getStudents,
      }}
    >
      {children}
    </ClassContext.Provider>
  );
};

export default ClassContextProvider;
