import "./SelectPlan.scss";
import React, { useEffect } from "react";
import { PlanI } from "../../../../interfaces/Plans";
import { returnLessonImage } from "../../../../utils/GeneralFunctions";
import { useFormikContext } from "formik";

type SelectPlanPropsT = {
  lessons: PlanI[];
};

interface FormValues {
  selectedPlans: PlanI[];
}

const SelectPlan = ({ lessons }: SelectPlanPropsT) => {
  const { setFieldValue, values } = useFormikContext<FormValues>();

  const handlePlan = async (plan: PlanI) => {
    const tempPlans = [...values.selectedPlans];
    const index = tempPlans.findIndex((e) => e.id === plan.id);

    if (index === -1) {
      tempPlans.push({ ...JSON.parse(JSON.stringify(plan)), pricingIndex: 0 });
    } else {
      tempPlans.splice(index, 1);
    }

    setFieldValue("selectedPlans", tempPlans);
  };

  const handlePricing = async (plan: PlanI, index: number) => {
    const tempPlans = values.selectedPlans.map((p) =>
      p.id === plan.id
        ? {
            ...p,
            pricingIndex: index,
            lessons: { ...JSON.parse(JSON.stringify(plan.lessons)) },
          }
        : p
    );
    setFieldValue("selectedPlans", [...tempPlans]);
  };

  return (
    <div className="select-plan-component shadow rounded p-4 bg-white">
      <h2 className="fs-16 mb-2">
        Selecciona los planes que deseas agregar al alumno seleccionado.
      </h2>
      <div className="d-flex flex-column gap-2">
        {lessons.map((plan, i) => {
          return (
            <div className="border-bottom pb-2" key={plan.id}>
              <div className="lesson-item  d-flex flex-column flex-md-row align-items-center p-3 gap-3">
                <div className="lesson-image-container">
                  {returnLessonImage(plan.name)}
                </div>
                <div className="flex-grow-1">
                  <h3 className="fs-14 fw-bolder">{plan.name}</h3>
                </div>
                {values.selectedPlans.findIndex((e) => e.id === plan.id) ===
                -1 ? (
                  <button
                    type="button"
                    className="btn btn-primary fs-14"
                    onClick={() => handlePlan(plan)}
                  >
                    + Agregar
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-danger fs-14"
                    onClick={() => handlePlan(plan)}
                  >
                    - Quitar
                  </button>
                )}
              </div>
              {values.selectedPlans.findIndex((e) => e.id === plan.id) !==
                -1 && (
                <div className="d-flex flex-column flex-md-row justify-content-center align-items-center gap-2">
                  {plan.lessons.pricing.map((pack, i) => {
                    const isActive =
                      values.selectedPlans.find((e) => e.id === plan.id)
                        ?.pricingIndex === i;
                    return (
                      <div
                        className={`pack-container ${
                          isActive ? "active primary-bg" : ""
                        }`}
                        onClick={() => handlePricing(plan, i)}
                        key={pack.id}
                      >
                        <h4>
                          {pack.reservations === 30
                            ? "Ilimitado"
                            : pack.reservations +
                              (pack.reservations > 1
                                ? " reservas"
                                : " reserva")}
                        </h4>
                        <h5>${pack.cost}</h5>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SelectPlan;
