import axiosInstance from "./AxiosConfig";

export const fetchClassesByScheduleId = async (scheduleId: string) => {
  return axiosInstance
    .get(`/schedule/${scheduleId}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

export const fetchStudentsFromClass = async (scheduleId: string) => {
  return axiosInstance
    .get("/teacher/student_by_schedule/" + scheduleId)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

export const fetchClassesByTeacher = async (date?: string) => {
  return axiosInstance
    .get(`/teacher/classes?date=${date}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

export const takeAttendance = async (
  scheduleId: string,
  usersIds: string[]
) => {
  return axiosInstance
    .post("/teacher/attendance", {
      scheduleId,
      userId: usersIds,
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const fetchAttendance = async (scheduleId: string) => {
  return axiosInstance
    .get(`/teacher/classes/${scheduleId}/attendance`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const addUserToClass = async (scheduleId: string, userId: string) => {
  return axiosInstance
    .post(`/teacher/add_user`, { scheduleId, userId, justThisOne: true })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const toggleClassStatusByTeacher = async (
  scheduleId: string,
  userId: string
) => {
  return axiosInstance
    .patch("/teacher/toggle", { scheduleId, userId })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw error;
    });
};

export async function fetchLessons() {
  return await axiosInstance
    .get(`/lesson/prices`)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
}

export async function newMembership(membershipData: any) {
	return await axiosInstance
		.post(`/users/buy_membership`, membershipData)
		.then((res) => {
			return res.data;
		})
		.catch((error) => {
			return error;
		});
}
