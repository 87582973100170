
import React from "react";
import QRScanner from "../../../../components/QRScanner/QRScanner";
import { useClassContext } from "../../../../context/ClassContext";

const OpenClass = () => {
  const { toggleClassStatus, clase } = useClassContext();

  return (
    <div className="px-4 d-flex flex-column align-content-center gap-4">
      <h3 className="text-center fs-20 fw-bold">
        {clase?.status === "CLOSED" ? "Abrir clase" : "Terminar clase"}
      </h3>
      <h5 className="text-center fs-16">
        Para {clase?.status === "CLOSED" ? "abrir" : "terminar"} la clase por
        favor presiona el boton de "Escanear QR" y escanea el código QR de la
        clase
      </h5>
      <div className="row justify-content-center">
        <button
          className="btn btn-primary px-4"
          onClick={() => toggleClassStatus(clase!.id)}
        >
          {clase!.status === "CLOSED" ? "Abrir" : "Cerrar"}
        </button>
      </div>
      <QRScanner buttonClassName="m-auto mb-4" onScan={toggleClassStatus} />
    </div>
  );
};

export default OpenClass;
