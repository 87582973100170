import { useFormikContext } from "formik";
import React from "react";

interface FormValues {
  file: string;
}

const Selectfile = () => {
  const { setFieldValue, values } = useFormikContext<FormValues>();

  const onFileChange = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        if (reader.result) {
          const imageString = reader.result.toString();
          setFieldValue("file", imageString);
        }
      };

      reader.onerror = (error) => {
        console.error("Error: ", error);
      };
    }
  };

  return (
    <div className="shadow rounded p-4 bg-white">
      <label htmlFor="file" className="form-label">
        Comprobante
      </label>
      <input
        className="form-control"
        type="file"
        id="file"
        accept="image/*"
        name="file"
        onChange={onFileChange}
      />
    </div>
  );
};

export default Selectfile;
