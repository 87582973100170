

import { useFormikContext } from "formik";
import React from "react";
import StudentsSelect from "../../../../components/StudentsSelect/StudentsSelect";
import { SelectOptionValueT } from "../../../../interfaces/Types";

interface FormValues {
  selectedStudent: SelectOptionValueT;
}

const SelectUser = () => {
  const { setFieldValue, values } = useFormikContext<FormValues>();

  const handleChange = (e: any) => {
    setFieldValue("selectedStudent", e);
  };

  return (
    <div className="shadow rounded p-4 bg-white">
      <h2 className="fs-16 mb-2">Selecciona un alumno</h2>
      <StudentsSelect
        name={"selectedStudent"}
        value={values.selectedStudent}
        handleChange={handleChange}
        id={"selectedStudent"}
      />
    </div>
  );
};

export default SelectUser;
