import "./ErrorRoute.scss";

function ErrorRoute() {
  return (
    <div className="error-route-main-container">
      <h1>Ups... parece que la página a la que quieres acceder no existe.</h1>
    </div>
  );
}

export default ErrorRoute;
