import React, { createContext, useContext, useEffect, useState } from "react";
import { PaymentI } from "../interfaces/Payments";
import { fetchAllPaymentsByTeacher } from "../services/Payments";

type PaymentsContextT = {
  payments: PaymentI[];
  getPaymentsByTeacher: () => void;
  handleSearch: (search: string) => void;
};

const PaymentsContext = createContext<PaymentsContextT>({
  payments: [],
  getPaymentsByTeacher: () => {},
  handleSearch: () => {},
});

export const usePaymentsContext = () => {
  return useContext(PaymentsContext);
};

const PaymentsContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [payments, setPayments] = useState<PaymentI[]>([]);
  const [paymentsBU, setPaymentsBU] = useState<PaymentI[]>([]);

  const getPaymentsByTeacher = async () => {
    await fetchAllPaymentsByTeacher()
      .then((res) => {
        setPayments(res);
        setPaymentsBU(res);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleSearch = (search: string) => {
    if (search.length > 0) {
      const words = search
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
        .split(" ");

      const tempPayments = [...payments];

      const results = tempPayments.filter((e) =>
        words.every(
          (word) =>
            e.user_lastname !== null &&
            e.user_name !== null &&
            (e.user_lastname
              ?.toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .indexOf(word) !== -1 ||
              e.user_name
                ?.toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .indexOf(word) !== -1)
        )
      );

      setPayments(results);
    } else {
      setPayments(paymentsBU);
    }
  };

  useEffect(() => {
    getPaymentsByTeacher();
  }, []);

  return (
    <PaymentsContext.Provider
      value={{ getPaymentsByTeacher, payments, handleSearch }}
    >
      {children}
    </PaymentsContext.Provider>
  );
};

export default PaymentsContextProvider;
