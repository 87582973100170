import PaymentsContextProvider from "../../context/PaymentsContext";
import PaymentsHeader from "./components/PaymentsHeader/PaymentsHeader";
import PaymentsTable from "./components/PaymentsTable/PaymentsTable";
import "./styles.scss";


const Pagos = () => {
  return (
    <PaymentsContextProvider>
      <div className="payments-page-main-container table-responsive">
        <div className="components-container shadow ">
          <PaymentsHeader />
          <PaymentsTable />
        </div>
      </div>
    </PaymentsContextProvider>
  );
};

export default Pagos;
