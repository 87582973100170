import React, { useEffect, useRef, useState } from "react";
import { Html5Qrcode } from "html5-qrcode";
import { notifyError } from "../../services/ToastNotifications";


type QRScannerPropsT = {
  onScan: (param: string) => void;
  buttonClassName?: string;
  mainClassName?: string;
};

const QRScanner = ({
  onScan,
  buttonClassName = "",
  mainClassName = "",
}: QRScannerPropsT) => {
  const scannerRef = useRef<HTMLDivElement>(null);
  const btnRef = useRef<HTMLButtonElement>(null);
  const html5QrCode = useRef<Html5Qrcode | null>(null);
  const [isWorking, setIsWorking] = useState<boolean>(false);

  const executeScanner = () => {
    try {
      setIsWorking(true);
      Html5Qrcode.getCameras()
        .then((devices) => {
          /**
           * devices would be an array of objects of type:
           * { id: "id", label: "label" }
           */
          if (devices && devices.length) {
            // .. use this to start scanning.
            html5QrCode.current = new Html5Qrcode(
              scannerRef.current?.id!,
              true
            );

            html5QrCode.current
              ?.start(
                { facingMode: "environment" },
                {
                  fps: 10, // Optional, frame per seconds for qr code scanning
                  qrbox: { width: 250, height: 250 }, // Optional, if you want bounded box UI
                },
                (decodedText, decodedResult) => {
                  // do something when code is read
                  // notifySuccess("Código QR leído con éxito.");
                  html5QrCode.current?.stop();
                  setIsWorking(false);
                  onScan(decodedText);
                },
                (errorMessage) => {
                  // parse error, ignore it.
                  console.error(errorMessage);
                }
              )
              .catch((err) => {
                // Start failed, handle it.
                console.error(err);
              });
          }
        })
        .catch((err) => {
          // handle err
          console.error(err);
          setIsWorking(false);
          notifyError(
            "No hemos podido encontrar una camara, por favor verifique que este habilitada"
          );
        });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    return () => {
      if (html5QrCode.current?.isScanning) {
        html5QrCode.current?.stop();
      }
      setIsWorking(false);
    };
  }, []);

  return (
    <div className="w-100 d-flex flex-column">
      <button
        className={"btn btn-primary " + buttonClassName}
        onClick={() => {
          !isWorking ? executeScanner() : html5QrCode.current?.stop();
          isWorking && setIsWorking(false);
        }}
      >
        {!isWorking ? "Escanear" : "Detener"}
      </button>
      <div
        ref={scannerRef}
        id="qr-reader"
        style={
          isWorking
            ? { width: "100%", height: "auto", aspectRatio: 1 }
            : { width: "0px", height: "auto", aspectRatio: 1 }
        }
      ></div>
    </div>
  );
};

export default QRScanner;
