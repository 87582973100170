import React from "react";
import FilterNav from "./components/FilterNav/FilterNav";
import TableContainer from "./components/TableContainer/TableContainer";
import ReportsContextProvider from "../../context/ReportsContext";
import SummaryReports from "./components/SummaryReports/SummaryReports";

const Reportes = () => {
  return (
    <ReportsContextProvider>
      <main className="reportes-main-container py-8 px-4 px-md-8 d-flex flex-column gap-4">
        <FilterNav />
        <SummaryReports />
        <TableContainer />
      </main>
    </ReportsContextProvider>
  );
};

export default Reportes;
