export const handleClicksOutsideRef = (
  event: MouseEvent,
  ref1: React.RefObject<HTMLElement>,
  setter: React.Dispatch<React.SetStateAction<boolean>>,
  ref2?: React.RefObject<HTMLElement>
) => {
  const target = event.target as HTMLElement; // Adjusted type assertion

  // Type guard to ensure target is an instance of Node
  if (!(target instanceof Node)) {
    return;
  }
  if (
    ref1.current &&
    !ref1.current.contains(target) &&
    (!ref2 || (ref2 && ref2.current && !ref2.current.contains(target)))
  ) {
    setter(false);
  }
};
