import { parse, format, parseISO } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { es } from "date-fns/locale";

const useDates = () => {
  function primaryFormatDate(inputDate: string) {
    let parsedDate = parse(inputDate, "yyyy-MM-dd", new Date());
    if (isNaN(parsedDate.getTime())) {
      parsedDate = parseISO(inputDate);
    }
    const formattedDate = format(parsedDate, "dd/MM/yyyy");
    return formattedDate;
  }

  function primaryFormatDateWithTime(inputDate: string): any {
    let parsedDate = parse(inputDate, "yyyy-MM-dd", new Date());
    if (isNaN(parsedDate.getTime())) {
      parsedDate = parseISO(inputDate);
    }
    if (isNaN(parsedDate.getTime())) {
      const newDate = new Date(inputDate);
      return primaryFormatDateWithTime(newDate.toISOString());
    }
    const formattedDate = format(parsedDate, "dd/MM/yyyy, hh:mm a");
    return formattedDate;
  }

  function fullFormatDate(inputDate: string): string {
    let parsedDate = parseISO(inputDate);
    if (!parsedDate) {
      parsedDate = parse(inputDate, "yyyy-MM-dd", new Date());
    }
    const formattedDate = format(parsedDate, "EEEE dd 'de' MMMM, yyyy", {
      locale: es,
    });
    return formattedDate;
  }

  function isBirthday(inputDate: string, classDate: string) {
    const parsedDate = parse(inputDate, "yyyy-MM-dd", new Date());
    const hermosilloTime = parseISO(classDate);

    return (
      parsedDate.getDate() === hermosilloTime.getDate() &&
      parsedDate.getMonth() === hermosilloTime.getMonth()
    );
  }

  function fetchFormatDate(isoDate: string) {
    return format(parseISO(isoDate), "yyyy-MM-dd");
  }

  function getHourInHermosillo(inputDate: string): string {
    const utcDate = new Date(inputDate); // Convert the string to a Date object
    const hermosilloDate = utcToZonedTime(utcDate, "America/Hermosillo"); // Convert to Hermosillo time
    return format(hermosilloDate, "HH:mm"); // Format the time
  }

  return {
    primaryFormatDate,
    isBirthday,
    fullFormatDate,
    getHourInHermosillo,
    primaryFormatDateWithTime,
    fetchFormatDate,
  };
};

export default useDates;
