import { array, mixed, number, object, string } from "yup";

export const AddPlanSchema = object({
  selectedStudent: object().shape({
    label: string(),
    value: string(),
  }),
  selectedPlans: array()
    .of(
      object().shape({
        id: string(),
        defaultLessonId: string(),
        name: string(),
        lessons: mixed(),
        pricingIndex: number(),
      })
    )
    .min(1, "Debes seleccionar por lo menos un plan"),
  file: string().required("Debes de agregar una imagen del comprobante"),
  paymentMethod: string(),
});
