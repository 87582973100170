import { Form } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useFormik } from "formik";
import { date, mixed, number, object, string } from "yup";
import { ReactSelectOptionT } from "../../../../interfaces/GeneralTypes";
import { InvoiceT } from "../../../../interfaces/Invoices";
import { useUserContext } from "../../../../context/UserContext";
import useDates from "../../../../hooks/useDates";
import { usePaymentsContext } from "../../../../context/PaymentsContext";
import { addPaymentToUser } from "../../../../services/Payments";
import {
  fetchAllStudents,
  fetchInvoicesByStudentId,
} from "../../../../services/Users";
import { UserT } from "../../../../interfaces/User";
import { paymentMethods } from "../../../../utils/Templates";
import StudentsSelect from "../../../../components/StudentsSelect/StudentsSelect";

const NewPaymentValidationSchema = object({
  date: string().required("Es necesario que ingreses una fecha"),
  selectedUser: object()
    .shape({
      label: string(),
      value: string(),
    })
    .required("Debes de seleccionar a un alumno."),
  invoiceId: string().required("Debes de seleccionar una factura."),
  amount: number()
    .min(1, "El monto debe ser mayor a cero")
    .required("Es necesario que ingreses un monto."),
  paymentMethod: string().required(
    "Es necesario que selecciones el método de pago"
  ),
  file: string(),
  noReference: string(),
});

type FormValues = {
  date: string;
  selectedUser: ReactSelectOptionT | null;
  invoiceId: string;
  amount: number;
  paymentMethod: string;
  file: any;
  noReference: string;
  // ...other fields
};

type NewPaymentFormT = {
  onHide: () => void;
};

const NewPaymentForm = ({ onHide }: NewPaymentFormT) => {
  const [usersSelectOptions, setUsersSelectOptions] = useState<
    Array<{ label: string; value: string }>
  >([]);
  const [userInvoices, setUserInvoices] = useState<Array<InvoiceT>>([]);
  const { primaryFormatDate } = useDates();
  const [selectedInvoice, setSelectedInvoice] = useState<InvoiceT | null>(null);
  const { user } = useUserContext();
  const { getPaymentsByTeacher } = usePaymentsContext();

  const formik = useFormik<FormValues>({
    initialValues: {
      date: "",
      selectedUser: null,
      invoiceId: "",
      amount: 0,
      paymentMethod: "",
      file: "",
      noReference: "",
    },
    validationSchema: NewPaymentValidationSchema,
    onSubmit: async (values) => {
      const data = {
        date: new Date(values.date).toISOString(),
        userId: values.selectedUser?.value,
        membershipId: selectedInvoice?.membershipId,
        invoiceId: parseInt(values.invoiceId),
        amount: parseFloat(values.amount.toString()),
        paymentMethod: values.paymentMethod,
        noReference: values.noReference,
        userCreatedId: user?.id,
        file: values.file,
      };

      await addPaymentToUser(data)
        .then(() => {
          getPaymentsByTeacher();
          resetAll();
          onHide();
        })
        .catch((error) => {
          console.error(error);
        });
    },
  });

  const resetAll = () => {
    formik.resetForm();
    setUserInvoices([]);
    setSelectedInvoice(null);
  };

  const handleSelectChange = (e: any) => {
    formik.setFieldValue("selectedUser", e);
  };

  const fetchUsers = async () => {
    await fetchAllStudents()
      .then((res) => {
        setUsersSelectOptions(
          res.map((student: UserT) => ({
            label: `${student.name} ${student.lastname}`,
            value: student.id,
          }))
        );
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getInvoicesByUserId = async () => {
    if (formik.values.selectedUser) {
      await fetchInvoicesByStudentId(formik.values.selectedUser.value)
        .then((res) => {
          setUserInvoices(res);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      setUserInvoices([]);
    }
  };

  const onFileChange = (e: any) => {
    console.error(e.target);
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        if (reader.result) {
          const imageString = reader.result.toString();
          formik.setFieldValue("file", imageString);
        }
      };

      reader.onerror = (error) => {
        console.error("Error: ", error);
      };
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    if (formik.values.selectedUser) {
      getInvoicesByUserId();
    } else {
      setUserInvoices([]);
      formik.setFieldValue("invoiceId", "");
    }
  }, [formik.values.selectedUser]);

  useEffect(() => {
    if (formik.values.invoiceId !== "") {
      const findIndex = userInvoices.findIndex(
        (e) => e.id === parseInt(formik.values.invoiceId)
      );
      setSelectedInvoice(userInvoices[findIndex]);
      formik.setFieldValue("amount", userInvoices[findIndex].totalAmount);
    } else {
      formik.setFieldValue("amount", 0);
    }
  }, [formik.values.invoiceId]);

  return (
    <Form
      onSubmit={formik.handleSubmit}
      className="needs-validation"
      noValidate
    >
      <div className="mb-3">
        <label className="form-label" htmlFor="date">
          Fecha:
        </label>
        <input
          className="form-control"
          type="date"
          name="date"
          id="date"
          value={formik.values.date}
          onChange={formik.handleChange}
        />
        {formik.touched.date && formik.errors.date && (
          <div className="invalid-form-field fs-16 fw-bold text-danger">
            {formik.errors.date}
          </div>
        )}
      </div>
      <div className="mb-3">
        <label className="form-label" htmlFor="selectedUser">
          Alumno:
        </label>
        <StudentsSelect
          className=""
          name="selectedUser"
          id="selectedUser"
          value={formik.values.selectedUser}
          handleChange={handleSelectChange}
        />
        {formik.touched.selectedUser && formik.errors.selectedUser && (
          <div className="invalid-form-field fs-16 text-danger fw-bold">
            {formik.errors.selectedUser}
          </div>
        )}
      </div>
      <div className="mb-3">
        <label className="form-label" htmlFor="invoiceId">
          Facturas:
        </label>
        <select
          className="form-control"
          name="invoiceId"
          id="invoiceId"
          value={formik.values.invoiceId}
          onChange={formik.handleChange}
        >
          <option value="">Seleccione una factura</option>
          {userInvoices.map((invoice) => {
            return (
              <option className="p-4" value={invoice.id} key={invoice.id}>
                {invoice.details[0]?.description} -{" "}
                {primaryFormatDate(invoice.dueDate)}
              </option>
            );
          })}
        </select>
        {formik.touched.invoiceId && formik.errors.invoiceId && (
          <div className="invalid-form-field fs-16 text-danger fw-bold">
            {formik.errors.invoiceId}
          </div>
        )}
      </div>
      <div className="mb-3">
        <label className="form-label" htmlFor="amount">
          Monto:
        </label>
        <input
          className="form-control"
          type="number"
          min={0}
          name="amount"
          id="amount"
          value={formik.values.amount}
          onChange={formik.handleChange}
        />
        {formik.touched.amount && formik.errors.amount && (
          <div className="invalid-form-field fs-16 text-danger fw-bold">
            {formik.errors.amount}
          </div>
        )}
      </div>
      <div className="mb-3">
        <label className="form-label" htmlFor="paymentMethod">
          Método de pago:
        </label>
        <select
          className="form-control"
          name="paymentMethod"
          id="paymentMethod"
          value={formik.values.paymentMethod}
          onChange={formik.handleChange}
        >
          <option value="">Seleccione un método de pago</option>
          {paymentMethods.map((paymentMethod) => {
            return (
              <option value={paymentMethod.value} key={paymentMethod.label}>
                {paymentMethod.label}
              </option>
            );
          })}
        </select>
        {formik.touched.paymentMethod && formik.errors.paymentMethod && (
          <div className="invalid-form-field fs-16 text-danger fw-bold">
            {formik.errors.paymentMethod}
          </div>
        )}
      </div>
      <div className="mb-3">
        <label htmlFor="file" className="form-label">
          Comprobante
        </label>
        <input
          className="form-control"
          type="file"
          id="file"
          accept="image/*"
          name="file"
          onChange={onFileChange}
        />
      </div>
      <div className="mb-3">
        <label className="form-label" htmlFor="noReference">
          Observación:
        </label>
        <input
          className="form-control"
          type="text"
          name="noReference"
          id="noReference"
          value={formik.values.noReference}
          onChange={formik.handleChange}
          placeholder="Observación"
        />
        {formik.touched.noReference && formik.errors.noReference && (
          <div className="invalid-form-field fs-16 text-danger fw-bold">
            {formik.errors.noReference}
          </div>
        )}
      </div>
      <button
        className="btn btn-secondary w-100"
        type="submit"
        disabled={formik.isSubmitting}
      >
        Registrar
      </button>
    </Form>
  );
};

export default NewPaymentForm;
