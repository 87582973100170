import { Form, Formik, FormikHelpers, FormikValues } from "formik";
import React, { useEffect, useState } from "react";
import SelectUser from "./components/SelectUser/SelectUser";
import SelectPlan from "./components/SelectPlan/SelectPlan";
import { fetchLessons, newMembership } from "../../services/ClassesService";
import { PlanI } from "../../interfaces/Plans";
import { AddPlanSchema } from "./schema/AddPlanSchema";
import { useUserContext } from "../../context/UserContext";
import { notifyError, notifySuccess } from "../../services/ToastNotifications";
import Selectfile from "./components/SelectFile/Selectfile";
import { useNavigate } from "react-router";
import SelectPaymentMethod from "./components/SelectPaymentMethod/SelectPaymentMethod";
import EditPlansAmounts from "./components/EditPlansAmounts/EditPlansAmounts";

const initialValues = {
  selectedStudent: null,
  selectedPlans: [],
  file: "",
  paymentMethod: "cash",
};

const AddPlan = () => {
  const [lessons, setLessons] = useState<PlanI[]>([]);
  const { user } = useUserContext();
  const navigate = useNavigate();

  const onSubmit = async (values: FormikValues) => {

    await Promise.all(
      values.selectedPlans.map(async (plan: PlanI) => {
        const planData = {
          userId: values.selectedStudent.value,
          planId: "230ca849-f427-41bd-b690-9935ab17f616",
          startDate: new Date(),
          freeClass: plan.lessons.pricing[plan.pricingIndex].reservations,
          duration: 1,
          typeDuration: "month",
          classId: plan.lessons.id,
          payment: {
            amount: plan.lessons.pricing[plan.pricingIndex].cost,
            paymentMethod: values.paymentMethod,
            noReference: "maestra-" + user?.name + " " + user?.lastname,
            userCreatedId: user?.id,
            file: values.file,
          },
        };

        await newMembership(planData).then((res) => {
          if (res.error) {
            notifyError(
              "Ha ocurrido un error con la membresía de " + plan.name
            );
          } else {
            notifySuccess(
              "Se ha generado correctamente la membresía de " + plan.name
            );
          }
        });
      })
    );
    navigate("/pagos");
  };

  // función para obtener las lessons, filtrando las que no deben de aparecer
  // tambien agregandoles el campo priceIndex
  const getLessons = async () => {
    fetchLessons()
      .then((res) => {
        const temp = [...res.data];

        temp.map((plan) => {
          plan.lessons.pricing = plan.lessons.pricing.filter(
            (e: any) => e.showFront
          );
          plan.pricingIndex = 0;
        });

        setLessons(temp.filter((e) => e.name !== "SCULPT ROOM").reverse());
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getLessons();
  }, []);

  return (
    <div className="add-plan-main-container p-4 pt-8">
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={AddPlanSchema}
      >
        {(formikProps) => (
          <Form className="d-flex flex-column gap-4">
            <SelectUser />
            {formikProps.values.selectedStudent && (
              <SelectPlan lessons={lessons} />
            )}
            {formikProps.values.selectedPlans.length > 0 && (
              <EditPlansAmounts />
            )}
            {formikProps.values.selectedPlans.length > 0 && <Selectfile />}
            {formikProps.values.file.length > 0 && <SelectPaymentMethod />}
            {formikProps.isValid && (
              <div className="row justify-content-center">
                <button
                  className="btn btn-primary px-4"
                  disabled={formikProps.isSubmitting}
                >
                  Agregar plan
                </button>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddPlan;
