import React, { createContext, useContext, useState } from "react";
import { ReportClassI } from "../interfaces/Classes";

type SummaryClassesT = {
  valid: number;
  invalid: number;
};

type ReportsContextT = {
  clases: ReportClassI[];
  setClases: React.Dispatch<React.SetStateAction<ReportClassI[]>>;
  setSummaryClasses: React.Dispatch<React.SetStateAction<SummaryClassesT>>;
  summaryClasses: SummaryClassesT;
};

const ReportsContext = createContext<ReportsContextT>({
  clases: [],
  setClases: () => {},
  summaryClasses: { valid: 0, invalid: 0 },
  setSummaryClasses: () => {},
});

export const useReportsContext = () => {
  return useContext(ReportsContext);
};

const ReportsContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [clases, setClases] = useState<ReportClassI[]>([]);
  const [summaryClasses, setSummaryClasses] = useState<SummaryClassesT>({
    valid: 0,
    invalid: 0,
  });
  return (
    <ReportsContext.Provider
      value={{ clases, setClases, summaryClasses, setSummaryClasses }}
    >
      {children}
    </ReportsContext.Provider>
  );
};

export default ReportsContextProvider;
