import React from "react";
import Logo from "../../assets/images/logos/logo.svg";
import TempImage from "../../assets/images/login.jpg";

import { useFormik } from "formik";
import { object, string } from "yup";
import { useUserContext } from "../../context/UserContext";
import { useAuth } from "../../hooks/useAuth";
import { notifyError } from "../../services/ToastNotifications";

const LoginFormValidationSchema = object({
  email: string()
    .email("Ingresa un correo válido")
    .required("Este campo es obligatorio"),
  password: string().required("Este campo es obligatorio"),
});

const Login = () => {
  const { login } = useAuth();
  const { handleLogin } = useUserContext();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: LoginFormValidationSchema,
    onSubmit: async (values) => {
      await login(values.email, values.password)
        .then((res) => {
          if (res.roles.some((e: any) => e.name === "TEACHER")) {
            localStorage.setItem("userToken", JSON.stringify(res));
            handleLogin();
          } else {
            notifyError("Este portal es solo para maestras de Wellness Studio");
          }
        })
        .catch((error) => {
          notifyError(error.response.data.message);
        });
    },
  });

  return (
    <section className="vh-100" style={{ backgroundColor: "#153442" }}>
      <div className="container py-5 h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col col-xl-10">
            <div className="card" style={{ borderRadius: "1rem" }}>
              <div className="row flex-nowrap g-0">
                <div
                  className="col-md-6 col-lg-5 d-none d-md-block"
                  style={{ flex: 1 }}
                >
                  <img
                    src={TempImage}
                    alt="login form"
                    className="img-fluid"
                    style={{ borderRadius: "1rem 0 0 1rem" }}
                  />
                </div>
                <div
                  className=" col-md-6 col-lg-7 d-flex align-items-center"
                  style={{ flex: 1 }}
                >
                  <div className="card-body p-4 p-lg-5 text-black">
                    <form onSubmit={formik.handleSubmit}>
                      <div className="d-flex align-items-center mb-3 pb-1">
                        <img src={Logo} alt={"wellness studio"} />
                      </div>

                      <h5
                        className="fw-normal mb-3 pb-3"
                        style={{ letterSpacing: 1 }}
                      >
                        Inicia sesión con tu cuenta
                      </h5>

                      <div className="form-outline mb-4">
                        <label className="form-label" htmlFor="email">
                          Correo
                        </label>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          className="form-control form-control-lg"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                        />
                        {formik.touched.email && formik.errors.email && (
                          <div className="invalid-form-field fs-16 text-danger fw-bold">
                            {formik.errors.email}
                          </div>
                        )}
                      </div>

                      <div className="form-outline mb-4">
                        <label className="form-label" htmlFor="password">
                          Contraseña
                        </label>
                        <input
                          type="password"
                          id="password"
                          name="password"
                          className="form-control form-control-lg"
                          value={formik.values.password}
                          onChange={formik.handleChange}
                        />
                        {formik.touched.password && formik.errors.password && (
                          <div className="invalid-form-field fs-16 text-danger fw-bold">
                            {formik.errors.password}
                          </div>
                        )}
                      </div>

                      <div className="pt-1 mb-4">
                        <button
                          className="btn btn-dark btn-lg btn-block"
                          type="submit"
                          disabled={formik.isSubmitting}
                        >
                          Iniciar sesión
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
