import { useClassContext } from "../../../../context/ClassContext";
import useDates from "../../../../hooks/useDates";
import { takeAttendance } from "../../../../services/ClassesService";
import { notifyError } from "../../../../services/ToastNotifications";
import "./Header.scss";
import React from "react";

const Header = () => {
  const {
    clase,
    setAddUserModal,
    setTakingAttendance,
    takingAttendance,
    attendance,
    setShowChangeTeacherModal,
  } = useClassContext();
  const { fullFormatDate, getHourInHermosillo } = useDates();

  const handleTakingAttendance = async () => {
    if (takingAttendance && clase && attendance.length > 0) {
      await takeAttendance(clase.id, attendance).catch((error) => {
        console.error(error);
      });
    }
    setTakingAttendance((prev) => !prev);
  };

  const handleShowModal = (type: "user" | "teacher") => {
    if (takingAttendance) {
      notifyError(
        "Debes de guardar la lista antes de agregar un alumno nuevo o cambiar de maestro."
      );
      return;
    }

    if (type === "user") {
      setAddUserModal(true);
    } else if (type === "teacher") {
      setShowChangeTeacherModal(true);
    }
  };

  if (!clase) return <></>;

  return (
    <div className="clase-page-header-main-container d-flex flex-column gap-2">
      <div className="row align-items-center justify-content-between mb-4">
        <h2 className="m-0 fs-36 fw-bolder">{clase?.lesson.name}</h2>
        <div className="row gap-2">
          <button
            className="btn btn-primary px-4 fs-12"
            onClick={() => handleShowModal("user")}
            disabled={clase.status === "CLOSED"}
          >
            Agregar alumno
          </button>
          <button
            className="btn btn-primary px-4 fs-12"
            onClick={() => handleShowModal("teacher")}
          >
            Cambiar maestro
          </button>
        </div>
      </div>
      <h3 className="fs-16">
        <b>Horario:</b> {getHourInHermosillo(clase.startDate)}
        {" - "}
        {getHourInHermosillo(clase.endDate)}
      </h3>
      <h3 className="fs-16">{clase?.location.name}</h3>
      <h4 className="fs-18">
        <b>Maestra:</b> {clase?.employee.name} {clase?.employee.lastname}
      </h4>
      <h4 className="text-end fw-bold mt-4">
        {fullFormatDate(clase!.startDate)}
      </h4>
      <div className="row justify-content-end">
        <button
          className="btn btn-success px-4 py-1 text-light"
          onClick={handleTakingAttendance}
          disabled={clase.status === "CLOSED"}
        >
          {!takingAttendance ? "Pasar lista" : "Guardar lista"}
        </button>
      </div>
    </div>
  );
};

export default Header;
