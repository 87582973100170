import axiosInstance from "./AxiosConfig";
import { notifyError } from "./ToastNotifications";

export const handleLogin = async (email: string, password: string) => {
  return axiosInstance
    .post("/sign-in", { email, password })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw error;
    });
};
