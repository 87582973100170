
import { UserT } from "../../../../interfaces/User";
import React from "react";

type UserListProps = {
  title: string;
  users: Array<UserT>;
  onUserClick: (student: UserT) => void;
  selectedUsers?: Array<UserT>;
};

export const UserList = ({
  title,
  users,
  onUserClick,
  selectedUsers,
}: UserListProps) => {
  return (
    <div className="search-result-container">
      <div className="row align-items-center gap-2 mb-3">
        <h3 className="fs-20">{title}</h3>
        <hr className="m-0 rounded" style={{ flex: 1, borderWidth: 4 }} />
      </div>
      {users.map(
        (user) =>
          (!selectedUsers ||
            (selectedUsers &&
              selectedUsers.findIndex((e) => e.id === user.id) === -1)) && (
            <div
              className="card py-2 px-3 mb-2"
              role="button"
              key={user.id}
              onClick={() => onUserClick(user)}
            >
              <h2 className="fs-18">
                {user.name} {user.lastname}
              </h2>
            </div>
          )
      )}
    </div>
  );
};
