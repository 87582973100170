import React from "react";
import { useReportsContext } from "../../../../context/ReportsContext";
import useDates from "../../../../hooks/useDates";
import { cleanLocation } from "../../../../utils/GeneralFunctions";

const TableContainer = () => {
  const { clases } = useReportsContext();
  const { primaryFormatDate, primaryFormatDateWithTime } = useDates();

  return (
    <div className="d-flex flex-column">
      {clases.map((clase, i) => {
        return (
          <div
            className="d-flex flex-column gap-1 border-bottom border-4 border-primary py-2"
            key={clase.startDate + "-" + i}
          >
            <h3 className="fs-16">
              <b>Valida: </b>
              {clase.is_valid ? "Valida" : "Invalida"}
            </h3>
            <h3 className="fs-16">
              <b>Clase: </b>
              {clase.lesson_name}
            </h3>
            <h3 className="fs-16">
              <b>Ubicación: </b>
              {cleanLocation(clase.location_name)}
            </h3>
            <h3 className="fs-16">
              <b>Fecha: </b>
              {primaryFormatDate(clase.startDate)}
            </h3>
            <h3 className="fs-16">
              <b>Hora: </b>
              {clase.start} - {clase.end}
            </h3>
            <h3 className="fs-16">
              <b>Abierta a: </b>
              {clase.opened_at
                ? primaryFormatDateWithTime(clase.opened_at)
                : "No abierta"}
            </h3>
            <h3 className="fs-16">
              <b>Cerrada a: </b>
              {clase.closed_at
                ? primaryFormatDateWithTime(clase.closed_at)
                : "No cerrada"}
            </h3>
            <h3 className="fs-16">
              <b>Asistentes: </b>
              {clase.alumnos_asistieron}
            </h3>
            <h3 className="fs-16">
              <b>Ausentes: </b>
              {clase.alumnos_no_asistieron}
            </h3>
          </div>
        );
      })}
    </div>
  );
};

export default TableContainer;
