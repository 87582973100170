import { useFormikContext } from "formik";
import React from "react";
import { paymentMethods } from "../../../../utils/Templates";

interface FormValues {
  paymentMethod: string;
}

const SelectPaymentMethod = () => {
  const { values, setFieldValue, handleChange } = useFormikContext<FormValues>();
  return (
    <div className="shadow rounded p-4 bg-white">
      <label className="form-label" htmlFor="paymentMethod">
        Método de pago:
      </label>
      <select
        className="form-control"
        name="paymentMethod"
        id="paymentMethod"
        value={values.paymentMethod}
        onChange={handleChange}
      >
        <option value="">Seleccione un método de pago</option>
        {paymentMethods.map((paymentMethod) => {
          return (
            <option value={paymentMethod.value} key={paymentMethod.label}>
              {paymentMethod.label}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default SelectPaymentMethod;
