import React, { useEffect, useState } from "react";
import Select from "react-select";
import { SelectOptionValueT } from "../../interfaces/Types";
import { fetchAllStudents } from "../../services/Users";
import { UserT } from "../../interfaces/User";

type StudentsSelectProps = {
  name: string;
  value: SelectOptionValueT | null;
  handleChange: (e: any) => void;
  className?: string;
  id: string;
};

const StudentsSelect = ({
  id,
  name,
  value,
  handleChange,
  className = "",
}: StudentsSelectProps) => {
  const [studentsOptions, setStudentsOptions] = useState<SelectOptionValueT[]>(
    []
  );

  const getStudents = async () => {
    try {
      const res = await fetchAllStudents();

      setStudentsOptions(
        res.map((student: UserT) => ({
          label: `${student.name} ${student.lastname}`,
          value: student.id,
        }))
      );
    } catch (error) {
      console.error(error);
    }
  };

  const filterOptions = (
    candidate: { label: string; value: string; data: any },
    input: string
  ) => {
    const words = input
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase()
      .split(" ");

    const studentName = candidate.label
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
    if (input) {
      return words.every((e) => studentName.indexOf(e) !== -1);
    }
    return true;
  };

  useEffect(() => {
    getStudents();
  }, []);

  return (
    <Select
      className={className}
      placeholder={"Selecciona un alumno"}
      name={name}
      id={id}
      isClearable
      value={value}
      onChange={handleChange}
      options={studentsOptions}
      filterOption={filterOptions}
    />
  );
};

export default StudentsSelect;
