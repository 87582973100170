import axiosInstance from "./AxiosConfig";

export const fetchAllPaymentsByTeacher = async () => {
  return axiosInstance
    .get("/teacher/payments")
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const addPaymentToUser = async (data: any) => {
  return axiosInstance
    .post(`/transactions/client`, data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw error;
    });
};
