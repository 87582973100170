import "./Navbar.scss";
import React, { useEffect, useRef, useState } from "react";
import WellnessLogo from "../../assets/images/logos/horizontal.png";
import AvatarImg from "../../assets/images/avatar-default.png";
import { useUserContext } from "../../context/UserContext";
import { handleClicksOutsideRef } from "../../utils/HandleClicks";
import { Link, useLocation, useNavigate } from "react-router-dom";

const Navbar = () => {
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const navigate = useNavigate();
  const { handleSignOut, user } = useUserContext();
  const dropdownRef: any = useRef(null);
  const menuBtnRef: any = useRef(null);
  const { pathname } = useLocation();

  const handleDropdownClick = (event: MouseEvent) =>
    handleClicksOutsideRef(event, dropdownRef, setOpenMenu, menuBtnRef);

  useEffect(() => {
    document.addEventListener("mousedown", handleDropdownClick);

    return () => {
      document.removeEventListener("mousedown", handleDropdownClick);
    };
  }, []);

  useEffect(() => {
    setOpenMenu(false);
  }, [pathname]);

  return (
    <nav>
      <img src={WellnessLogo} alt="wellness" onClick={() => navigate("/")} />
      <ul>
        <li
          className="user"
          onClick={() => setOpenMenu(!openMenu)}
          ref={menuBtnRef}
        >
          <span>
            {user?.name} {user?.lastname}
          </span>
          <img src={AvatarImg} alt="avatar" />
        </li>
      </ul>
      <div
        ref={dropdownRef}
        className={`sub-menu-options ${openMenu ? "open" : ""}`}
      >
        <Link to="/pagos" role="button">
          <div>⁠Cobrar cupo fijo</div>
        </Link>
        <Link to="/agregar-plan" role="button">
          <div>Cobrar reservas sueltas</div>
        </Link>
        <Link to="/reportes" role="button">
          <div>Reporte de clases</div>
        </Link>
        {/* <Link href="/pagos" role="button" onClick={() => router.push("escanear")}>
          Escanear código
        </Link> */}
        <div role="button" onClick={handleSignOut}>
          Cerrar sesión
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
