import { ReactNode, useEffect, useState } from "react";
import "./MainLayout.scss";

import { Outlet } from "react-router-dom";
import { useUserContext } from "../context/UserContext";
import Navbar from "../components/navbar/Navbar";

interface MainLayoutPropsI {}

function MainLayout(props: MainLayoutPropsI) {
  const [termsModalShow, setTermsModalShow] = useState<boolean>(false);

  const { user } = useUserContext();

  return (
    <div className="layout-main-container">
      {user && <Navbar />}
      <Outlet />
    </div>
  );
}

export default MainLayout;
