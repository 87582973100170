import toast from "react-hot-toast";

export const notifySuccess = (message: string) => {
	toast.success(message, {
		className: "toast-notification",
		style: { background: "#0d8a0d", color: "#ffffff", fontSize: "1.2rem" },
		position: "top-right",
		duration: 5000,
	});
};

export const notifyError = (message: string) => {
	toast.error(message, {
		className: "toast-notification error",
		style: { background: "#a12727", color: "#ffffff", fontSize: "1.2rem" },
		position: "top-right",
		duration: 5000,
	});
};

export const notifyWarning = (message: string) => {
	toast(message, {
		className: "toast-notification",
		icon: "⚠️",
		style: {
			background: "#ffac4a",
			color: "#ffffff",
			fontSize: "1.6rem",
		},
		position: "top-right",
		duration: 5000,
	});
};