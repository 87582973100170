import { useFormik } from "formik";

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Select from "react-select";
import { object, string } from "yup";
import { ReactSelectOptionT } from "../../../../interfaces/GeneralTypes";
import {
  changeTeacherForSchedule,
  fetchAllTeachers,
} from "../../../../services/Teachers";
import { UserT } from "../../../../interfaces/User";
import { useClassContext } from "../../../../context/ClassContext";

const validationSchemaTeacher = object({
  selectedTeacher: object().shape({
    label: string(),
    value: string(),
  }),
});

type FormValues = {
  selectedTeacher: ReactSelectOptionT | null;
  // ...other fields
};

const ChangeTeacher = () => {
  const { clase, setShowChangeTeacherModal } = useClassContext();
  const [teacherOptions, setTeacherOptions] = useState<
    Array<{ label: string; value: string }>
  >([]);
  const navigate = useNavigate();

  const formik = useFormik<FormValues>({
    initialValues: {
      selectedTeacher: null,
    },
    validationSchema: validationSchemaTeacher,
    onSubmit: async (values) => {
      const data = {
        employeeId: values.selectedTeacher?.value,
      };
      if (clase) {
        changeTeacherForSchedule(data, clase.id)
          .then((res) => {
            setShowChangeTeacherModal(false);
            navigate("/");
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
  });

  const handleSelectChange = (e: any) => {
    formik.setFieldValue("selectedTeacher", e);
  };

  const getTeacherOptions = () => {
    fetchAllTeachers()
      .then((res) => {
        setTeacherOptions(
          res.map((teacher: UserT) => ({
            label: `${teacher.name} ${teacher.lastname}`,
            value: teacher.id,
          }))
        );
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const filterOptions = (
    candidate: { label: string; value: string; data: any },
    input: string
  ) => {
    const words = input
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase()
      .split(" ");

    const studentName = candidate.label
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
    if (input) {
      return words.every((e) => studentName.indexOf(e) !== -1);
    }
    return true;
  };

  useEffect(() => {
    getTeacherOptions();
  }, []);

  return (
    <form onSubmit={formik.handleSubmit}>
      <div>
        <label className="form-label" htmlFor="selectedUser">
          Maestra:
        </label>
        <Select
          className=""
          placeholder={"Selecciona una maestra"}
          name="selectedUser"
          id="selectedUser"
          isClearable
          value={formik.values.selectedTeacher}
          onChange={handleSelectChange}
          options={teacherOptions}
          filterOption={filterOptions}
        />
        {formik.touched.selectedTeacher && formik.errors.selectedTeacher && (
          <div className="invalid-form-field fs-16 text-danger fw-bold">
            {formik.errors.selectedTeacher}
          </div>
        )}
      </div>
      <div className="mt-4">
        <button
          className="btn btn-primary w-100"
          type="submit"
          disabled={formik.isSubmitting}
        >
          Guardar
        </button>
      </div>
    </form>
  );
};

export default ChangeTeacher;
