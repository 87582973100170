import "./RegularModal.scss";
import React from "react";
import { Modal } from "react-bootstrap";
import { ModalSizesT } from "../../../interfaces/GeneralTypes";

type RegularModalProps = {
  className?: string;
  children: React.ReactNode;
  show: boolean;
  onHide: () => void;
  title?: string;
  size?: ModalSizesT;
};

const RegularModal = ({
  className,
  children,
  show,
  onHide,
  title,
  size = "sm",
}: RegularModalProps) => {
  return (
    <Modal
      className="regular-modal-component"
      show={show}
      onHide={onHide}
      size={size}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        {title && (
          <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
        )}
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
};

export default RegularModal;
