import React, { useEffect, useState } from "react";
import { Iclass } from "../../interfaces/Classes";
import { compareStartDateClass } from "../../utils/CompareFunctions";
import { fetchClassesByTeacher } from "../../services/ClassesService";
import ClassCard from "../ClassCard/ClassCard";
import useDates from "../../hooks/useDates";
import { ReactComponent as ChevronArrow } from "../../assets/icons/chevron-rigth-rounded-square.svg";

type NextClassesPropsT = {
  nextDates: string[];
};

const NextClasses = ({ nextDates }: NextClassesPropsT) => {
  const [classes, setClasses] = useState<Iclass[]>([]);
  const [dateIndex, setDateIndex] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const { fullFormatDate } = useDates();

  const getClasses = async () => {
    setLoading(true);
    fetchClassesByTeacher(nextDates[dateIndex])
      .then((res) => {
        setClasses(res.sort(compareStartDateClass));
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getClasses();
  }, [dateIndex]);

  return (
    <div className="next-classes-main-container py-8 px-4">
      <h2 className="fs-18 mb-2">Clases del:</h2>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <ChevronArrow
          className={dateIndex > 0 && !loading ? "" : "opacity-50"}
          style={{ transform: "rotate(180deg)", userSelect: "none" }}
          role="button"
          onClick={
            dateIndex > 0 && !loading
              ? () => setDateIndex((prev) => prev - 1)
              : () => {}
          }
        />
        <h2 className="m-0 fw-bold fs-18">
          {fullFormatDate(nextDates[dateIndex])}
        </h2>
        <ChevronArrow
          className={
            dateIndex < nextDates.length - 1 && !loading ? "" : "opacity-50"
          }
          style={{ userSelect: "none" }}
          role="button"
          onClick={
            dateIndex < nextDates.length - 1 && !loading
              ? () => setDateIndex((prev) => prev + 1)
              : () => {}
          }
        />
      </div>
      <div className="classes-cards-container d-flex flex-column gap-3">
        {classes.map((clase) => {
          return <ClassCard clase={clase} key={clase.schedule_id} />;
        })}
      </div>
    </div>
  );
};

export default NextClasses;
