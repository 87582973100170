import axiosInstance from "./AxiosConfig";

export const fetchAllTeachers = async () => {
  return axiosInstance
    .get("users?filterBy=TEACHER")
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const changeTeacherForSchedule = async (
  data: any,
  scheduleId: string
) => {
  return axiosInstance
    .put(`schedule/${scheduleId}`, data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const fetchReports = async (initDate: string, endDate: string) => {
  const params = {
    fromDate: initDate,
    toDate: endDate,
  };
  return axiosInstance
    .get("teacher/report", { params })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw error;
    });
};
