import { ReactComponent as PilatesIcon } from "../assets/icons/pilates-icon.svg";
import { ReactComponent as BarreIcon } from "../assets/icons/barre-icon.svg";
import { ReactComponent as SculptIcon } from "../assets/icons/sculpt-icon.svg";

import { LessonT } from "../interfaces/Types";

export const cleanLocation = (location: string) => {
  return location
    .replaceAll("PILATES REFORMER", "")
    .replaceAll("BARRE POINT", "")
    .trim();
};

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export function returnLessonImage(lesson: LessonT) {
  switch (lesson) {
    case "PILATES REFORMER":
      return <PilatesIcon />;
      break;
    case "BARRE POINT":
      return <BarreIcon />;
      break;
    case "SCULPT ROOM":
      return <SculptIcon />;
      break;

    default:
      break;
  }
}
