import { createContext, useContext, useEffect, useState } from "react";
import React from "react";
import CustomLoader from "../components/CustomLoader/CustomLoader";
import { UserT } from "../interfaces/User";
import { useAuth } from "../hooks/useAuth";

type UserContextT = {
  user: UserT | null;
  handleSignOut: () => void;
  handleLogin: () => void;
};

const UserContext = createContext<UserContextT>({
  user: null,
  handleSignOut: () => {},
  handleLogin: () => {},
});

export const useUserContext = () => {
  return useContext(UserContext);
};

const UserContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [userToken, setUserToken] = useState<string | null>(null);
  const [user, setUser] = useState<UserT | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const { signOut } = useAuth();

  const getUser = () => {
    if (localStorage.getItem("userToken")) {
      const userTemp = JSON.parse(localStorage.getItem("userToken")!);
      setUser(userTemp.user);
      setLoading(false);
    }
  };

  const handleLogin = () => {
    const token = localStorage.getItem("userToken");
    if (token) {
      const parsedToken = JSON.parse(token);
      setUserToken(parsedToken.access_token);
    } else {
      setLoading(false);
    }
  };

  const handleSignOut = () => {
    signOut();
    setUserToken(null);
    setUser(null);
  };

  useEffect(() => {
    if (userToken) {
      getUser();
    }
  }, [userToken]);

  useEffect(() => {
    handleLogin();
  }, []);

  return (
    <UserContext.Provider value={{ user, handleSignOut, handleLogin }}>
      {loading ? (
        <div style={{ height: "100vh" }}>
          <CustomLoader />
        </div>
      ) : (
        children
      )}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
