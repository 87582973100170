import { Iclass } from "../interfaces/Classes";


export function compareStartDateClass(a: Iclass, b: Iclass) {
  const dateA = new Date(a.schedule_startDate);
  const dateB = new Date(b.schedule_startDate);
  if (dateA > dateB) {
    return 1;
  } else if (dateA < dateB) {
    return -1;
  } else {
    return 0;
  }
}
