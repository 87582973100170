"use client";

import React, { useState } from "react";
import RegularModal from "../../../../components/Modals/RegularModal/RegularModal";
import NewPaymentForm from "../NewPaymentForm/NewPaymentForm";


const NewPaymentBtn = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  return (
    <>
      <button
        className="btn btn-primary px-3"
        type="button"
        onClick={() => setShowModal(true)}
      >
        Registrar un pago
      </button>
      <RegularModal
        show={showModal}
        onHide={() => setShowModal(false)}
        title="Registrar pago"
        size="lg"
      >
        <NewPaymentForm onHide={() => setShowModal(false)} />
      </RegularModal>
    </>
  );
};

export default NewPaymentBtn;
