import React, { useEffect, useState } from "react";
import NewPaymentBtn from "../NewPaymentBtn/NewPaymentBtn";
import { usePaymentsContext } from "../../../../context/PaymentsContext";

const PaymentsHeader = () => {
  const [searchText, setSearchText] = useState<string>("");
  const [debouncedText, setDebouncedText] = useState<string>("");
  const { handleSearch } = usePaymentsContext();

  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedText(searchText);
    }, 500);

    return () => {
      clearTimeout(timerId);
    };
  }, [searchText]);

  useEffect(() => {
    handleSearch(debouncedText);
  }, [debouncedText]);

  return (
    <div className="payments-header-main-container">
      <div className="row justify-content-between align-items-center">
        <h2>Pagos</h2>
        <div className="row gap-4">
          <input
            className="rounded py-1 px-2"
            type="text"
            name="search"
            id="search"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            placeholder="Buscar por nombre"
          />
          <NewPaymentBtn />
        </div>
      </div>
    </div>
  );
};

export default PaymentsHeader;
