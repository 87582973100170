import "./ClassNav.scss";
import React from "react";

const ClassNav = () => {
  return (
    <div className="class-nav-main-container flex-wrap">
      <h2>Alumnos</h2>
      <hr />
      {/* <ul>
        <li
          className={filter === "all" ? "active" : ""}
          onClick={() => handleClassFilter("all")}
          role="button"
        >
          Todos
        </li>
        <li
          className={filter === "attended" ? "active" : ""}
          onClick={() => handleClassFilter("attended")}
          role="button"
        >
          Asistieron
        </li>
        <li
          className={filter === "notAttended" ? "active" : ""}
          onClick={() => handleClassFilter("notAttended")}
          role="button"
        >
          Ausentes
        </li>
        <li
          className={filter === "new" ? "active" : ""}
          onClick={() => handleClassFilter("new")}
          role="button"
        >
          Nuevos
        </li>
      </ul> */}
    </div>
  );
};

export default ClassNav;
