import axiosInstance from "./AxiosConfig";

export const fetchAllStudents = async () => {
  return axiosInstance
    .get("users?filterBy=CLIENT")
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const fetchInvoicesByStudentId = async (studentId: string) => {
  return axiosInstance
    .get(`/invoices/client/${studentId}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw error;
    });
};
